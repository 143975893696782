import React, { createContext, useContext, useEffect, useState } from 'react';
import shootSvc from '../services/shootService';

export const ShootContext = createContext();

export function ShootContextProvider({ children }) {
  const shoots = useProvideShoots();
  return (
    <ShootContext.Provider value={shoots}>{children}</ShootContext.Provider>
  );
}

function useProvideShoots() {
  const [shoots, setShoots] = useState();
  useEffect(() => {
    getShoots();
  }, []);
  function getShoots() {
    shootSvc
      .getShoots()
      .then((res) => {
        if (!!res.data) {
          setShoots(res.data);
        } else {
          alert('Error getting shoots');
        }
      })
      .catch((err) => alert('Error getting shoots'));
  }
  return {
    shoots,
    setShoots,
    getShoots,
  };
}

export function useShoots() {
  return useContext(ShootContext);
}
