import axios from 'axios';
import baseURL from './apiURL';

const shootSvc = {
  getShootById,
  getShootByLocationId,
  getShoots,
  createShoot,
  updateShoot,
  deleteShoot,
};

function getShootById(id) {
  return new Promise((resolve, reject) => {
    if (!!id) {
      axios({ method: 'get', url: `${baseURL}/shoot/${id}` })
        .then((res) => {
          if (!!res && !!res.status && !!res.data && res.status === 200) {
            resolve({
              success: true,
              message: `Fetched shoot`,
              data: res.data,
            });
          } else {
            reject({ success: false, message: 'Unable to find shoot' });
          }
        })
        .catch((err) => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing id' });
    }
  });
}
function getShootByLocationId(locationId) {
  return new Promise((resolve, reject) => {
    if (!!locationId) {
      axios({
        method: 'get',
        url: `${baseURL}/shoot/?locationId=${locationId}}`,
      })
        .then((res) => {
          if (!!res && !!res.status && !!res.data && res.status === 200) {
            resolve({
              success: true,
              message: `Fetched shoot`,
              data: res.data,
            });
          } else {
            reject({ success: false, message: 'Unable to find shoot' });
          }
        })
        .catch((err) => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing shoot id' });
    }
  });
}

function getShoots() {
  return new Promise((resolve, reject) => {
    axios({ method: 'get', url: `${baseURL}/shoot/all` })
      .then((res) => {
        if (!!res && !!res.status && !!res.data && res.status === 200) {
          resolve({
            success: true,
            message: `Fetched shoots`,
            data: res.data,
          });
        } else {
          reject({ success: false, message: 'Unable to find shoots' });
        }
      })
      .catch((err) => {
        reject({
          success: false,
          message: 'An error occurred, please try again',
        });
      });
  });
}

function createShoot(token, shoot) {
  function isValid(o) {
    return (
      !!o &&
      !!o.locationId &&
      !!o.date &&
      !!o.endDate &&
      !!o.status &&
      !!o.photographer
    );
  }
  return new Promise((resolve, reject) => {
    if (!!token && !!shoot && isValid(shoot)) {
      axios({
        method: 'post',
        url: `${baseURL}/shoot/`,
        data: shoot,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Created shoot`,
            });
          } else {
            reject({ success: false, message: 'Unable to create shoot' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}
function updateShoot(token, shootId, shoot) {
  function isValid(o) {
    return !!o && !!o.locationId && !!o.date && !!o.status && !!o.photographer;
  }
  return new Promise((resolve, reject) => {
    if (!!token && !!shootId && !!shoot && isValid(shoot)) {
      axios({
        method: 'put',
        url: `${baseURL}/shoot/${shootId}`,
        data: shoot,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Updated shoot`,
            });
          } else {
            reject({ success: false, message: 'Unable to update shoot' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

function deleteShoot(token, shootId) {
  return new Promise((resolve, reject) => {
    if (!!token && !!shootId) {
      axios({
        method: 'delete',
        url: `${baseURL}/shoot/${shootId}`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Deleted shoot`,
            });
          } else {
            reject({ success: false, message: 'Unable to delete shoot' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

export default shootSvc;
