import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useToken } from '../context/authContext';
import { useLocations } from '../context/locationsContext';
import { useShoots } from '../context/shootContext';
import notificationSvc from '../services/notificationService';
/**
  shootId: String,
  locationId: String,
  title: String,
  message: String,
  albumURL: String,
  success: Number,
  failed: Number,
 */
export default function NotificationForm(notification) {
  const token = useToken();
  const { locations } = useLocations();
  const [filteredLocations, setFilteredLocations] = useState(sortLocations());
  const [locationId, setLocationId] = useState();
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [albumURL, setAlbumURL] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(); // { status, message }
  useEffect(() => {
    if (!!notification) {
      if (!!notification.locationId) setLocationId(notification.locationId);
      if (!!notification.title) setTitle(notification.title);
      if (!!notification.message) setMessage(notification.message);
      if (!!notification.albumURL) setAlbumURL(notification.albumURL);
    }
  }, [notification]);

  function createNotification() {
    setIsLoading(true);
    notificationSvc
      .createNotification(token, {
        locationId,
        title,
        message,
        albumURL,
      })
      .then((res) => {
        if (!!res && !!res.success) {
          setApiStatus({
            status: 'success',
            message: `Successfully sending notifications`,
          });
          setIsLoading(false);
        } else {
          setApiStatus({
            status: 'failed',
            message: 'Unable to create notification',
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setApiStatus({
          status: 'failed',
          message: 'Unable to create notification',
        });
        setIsLoading(false);
      });
  }
  function sortLocations() {
    const sorted = locations.sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
  }

  return (
    <div className="form-container" style={{ maxWidth: '500px' }}>
      <div className="row center">
        <a
          href="https://unicode.org/emoji/charts/full-emoji-list.html"
          target="_blank"
        >
          Emoji's
        </a>
      </div>
      {/* Location Select */}
      <div className="row">
        <FormControl>
          <InputLabel id="demo-simple-select-label">Location</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={locationId}
            onChange={(e) => {
              setLocationId(e.target.value);
            }}
            style={{ minWidth: '300px' }}
          >
            <MenuItem value="all">Notify All Users</MenuItem>
            {!!filteredLocations &&
            !!filteredLocations.length &&
            filteredLocations.length > 1 ? (
              filteredLocations.map((l, i) => (
                <MenuItem key={i} value={l._id}>
                  {l.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">No locations to display</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      {/* Title input */}
      <div className="row">
        <TextField
          id="title"
          label="Title"
          variant="outlined"
          value={!!title ? title : ''}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      {/* Message input */}
      <div className="row">
        <TextField
          id="message"
          label="Message"
          variant="outlined"
          value={!!message ? message : ''}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="row">
        <TextField
          id="album_url"
          label="Album URL"
          variant="outlined"
          value={!!albumURL ? albumURL : ''}
          onChange={(e) => setAlbumURL(e.target.value)}
        />
      </div>
      <div className="row center">
        {!!apiStatus ? (
          <p style={apiStatus.success === false ? { color: 'red' } : {}}>
            {apiStatus.message}
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        {!!isLoading && isLoading ? (
          <h1>Loading</h1>
        ) : (
          <Button variant="outlined" onClick={createNotification}>
            Send Notification
          </Button>
        )}
      </div>
    </div>
  );
}
