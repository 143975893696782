import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useToken } from "../context/authContext";
import locationSvc from "../services/locationService";

// shape = {name,description,coords,warnings,images,w3w}
// Needs to work for new and existing locations

export default function LocationForm({ location }) {
  const token = useToken();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const [warnings, setWarnings] = useState([]);
  const [w, setW] = useState();
  const [w3w, setW3w] = useState();
  const [apiStatus, setApiStatus] = useState(); // { success, message }
  const [newImg, setNewImg] = useState();
  const [imgStatus, setImgStatus] = useState(); // { success, message }
  const [imgUploading, setImgUploading] = useState(false);
  const [images, setImages] = useState();
  useEffect(() => {
    if (!!location) {
      if (!!location.name) setName(location.name);
      if (!!location.description) setDescription(location.description);
      if (!!location.coords) {
        setLat(location.coords.lat);
        setLon(location.coords.lon);
      }
      if (!!location.warnings) setWarnings(location.warnings);
      if (!!location.w3w) setW3w(location.w3w);
    }
  }, [location]);

  function updateWarning(warning) {
    if (warnings.includes(warning)) {
      const n = warnings.filter((w) =>
        w.toLowerCase() === warning.toLowerCase() ? false : true
      );
      setWarnings(n);
    } else {
      const n = warnings;
      n.push(warning);
      setWarnings(n);
    }
    setW();
  }

  function createLocation() {
    let coords = { lat, lon };
    setApiStatus();
    locationSvc
      .createLocation(token, {
        name,
        description,
        coords,
        warnings,
        w3w,
        images: Array.isArray(images) ? images : [],
      })
      .then((res) => {
        if (res.success) {
          setApiStatus({ success: true, message: "Created Location" });
        } else {
          setApiStatus({
            success: false,
            message: "Failed to create Location",
          });
        }
      })
      .catch((err) => {
        setApiStatus({
          success: false,
          message:
            !!err && !!err.message ? err.message : "Failed to create Location",
        });
      });
  }
  function updateLocation(locationId) {
    let coords = { lat, lon };
    setApiStatus();
    locationSvc
      .updateLocation(token, locationId, {
        name,
        description,
        coords,
        warnings,
        w3w,
        images: !!images ? images : !!location.images ? location.images : [],
      })
      .then((res) => {
        if (res.success) {
          setApiStatus({ success: true, message: "Updated Location" });
        } else {
          setApiStatus({
            success: false,
            message: "Failed to update Location",
          });
        }
      })
      .catch(() => {
        setApiStatus({
          success: false,
          message: "Failed to update Location",
        });
      });
  }

  async function handleFileUpload(e) {
    if (!!e && !!e.target && !!e.target.files && !!e.target.files[0]) {
      let photo = e.target.files[0];
      const resizedImage = await doTheResize(photo);
      if (!!resizedImage) {
        const img = await blobToDataURL(resizedImage);
        var i = new Image();
        i.onload = function () {
          console.log(i.width, i.height);
        };
        i.src = img;
        setNewImg(img);
      }
    }
  }
  async function doTheResize(photo) {
    let canvas = await readPhoto(photo);

    while (canvas.width >= 800) {
      canvas = scaleCanvas(canvas, 0.5);
    }

    if (canvas.width > 800) {
      canvas = scaleCanvas(canvas, 800 / canvas.width);
    }

    return new Promise((resolve) => {
      canvas.toBlob(resolve, "image/jpeg", 0.95);
    });
  }

  const readPhoto = async (photo) => {
    const canvas = document.createElement("canvas");
    const img = document.createElement("img");
    const ctx = canvas.getContext("2d");

    // create img element from File object
    img.src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.readAsDataURL(photo);
    });
    await new Promise((resolve) => {
      img.onload = resolve;
    });

    // draw image in canvas element
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.fillStyle = "#fff"; /// set white fill style
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    // have to set a BG colour for jpg

    return canvas;
  };
  const scaleCanvas = (canvas, scale) => {
    const scaledCanvas = document.createElement("canvas");
    scaledCanvas.width = canvas.width * scale;
    scaledCanvas.height = canvas.height * scale;

    scaledCanvas
      .getContext("2d")
      .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

    return scaledCanvas;
  };
  function blobToDataURL(blob) {
    return new Promise((fulfill, reject) => {
      let reader = new FileReader();
      reader.onerror = reject;
      reader.onload = (e) => fulfill(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  function upload() {
    if (typeof name === "undefined" || name === null || name === "") {
      alert("Enter location name before uploading");
    } else {
      setImgUploading(true);
      locationSvc
        .uploadImage(token, location._id, name, newImg)
        .then((res) => {
          if (!!res && !!res.success && res.success && res.imgURL) {
            setImages([res.imgURL]);
            setImgUploading(false);
            setImgStatus({
              success: true,
              message: "Image Uploaded Successfully",
            });
          } else {
            setImgUploading(false);
            setImgStatus({
              success: false,
              message: "An error occurred",
            });
          }
        })
        .catch((err) => {
          setImgUploading(false);
          setImgStatus({ success: false, message: "Server error occurred" });
        });
    }
  }
  return (
    <div className="form-container">
      {/* Name input */}
      {!!location && !!location.images && !!location.images[0] && (
        <div className="row center">
          <img src={location.images[0]} style={{ maxWidth: "300px" }} />
        </div>
      )}
      <div className="row center">
        <label for="file">Upload New</label>
        <input
          id="file"
          type="file"
          onChange={handleFileUpload}
          accept="image/jpeg"
        />
      </div>
      {typeof newImg !== "undefined" && newImg !== null && (
        <>
          <div className="row center">
            <img
              id="new-image"
              src={newImg}
              style={{ maxWidth: "300px", height: "auto" }}
            />
          </div>
          <div className="row space-between">
            <Button onClick={() => setNewImg()} variant="outlined">
              Cancel
            </Button>
            <Button onClick={upload} variant="outlined">
              Upload New
            </Button>
          </div>
          {imgUploading && (
            <div className="row center">
              <h2>Loading</h2>
            </div>
          )}
          {!!imgStatus && (
            <div className="row center">
              <p style={imgStatus.success === false ? { color: "red" } : {}}>
                {imgStatus.message}
              </p>
            </div>
          )}
        </>
      )}

      <div className="row">
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          value={!!name ? name : ""}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      {/* Desc input */}
      <div className="row">
        <TextField
          id="Description"
          label="Description"
          variant="outlined"
          value={!!description ? description : ""}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      {/* Coord input */}
      <div className="row space-between">
        <TextField
          id="Lat"
          label="Lat"
          variant="outlined"
          value={lat}
          onChange={(e) => setLat(e.target.value)}
        />
        <TextField
          id="Lon"
          label="Lon"
          variant="outlined"
          value={lon}
          onChange={(e) => setLon(e.target.value)}
        />
      </div>
      {/* w3w */}
      <div className="row">
        <TextField
          id="w3w"
          label="what3words"
          variant="outlined"
          value={!!w3w ? w3w : ""}
          onChange={(e) => setW3w(e.target.value)}
        />
      </div>
      {/*  warnings input */}
      <div className="row">
        <TextField
          id="w"
          label="Warning Input"
          variant="outlined"
          value={w}
          onChange={(e) => setW(e.target.value)}
        />
        <Button onClick={() => updateWarning(w)} variant="outlined">
          Add Warning
        </Button>
      </div>
      <div className="row center">
        <h3>Warnings</h3>
      </div>
      <div className="row" style={{ flexWrap: "wrap" }}>
        {!!warnings && !!warnings.length && warnings.length > 0 ? (
          warnings.map((w) => (
            <p
              style={{
                borderRadius: "8px",
                border: "2px solid black",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              {w}&nbsp;&nbsp;&nbsp;&nbsp;
              <span
                style={{
                  color: "red",
                  fontSize: "28px",
                  margin: "0px",
                  padding: "5px",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => updateWarning(w)}
              >
                X
              </span>
            </p>
          ))
        ) : (
          <p>No warnings to display</p>
        )}
      </div>
      <div className="row center">
        {!!apiStatus ? (
          <p style={apiStatus.success === false ? { color: "red" } : {}}>
            {apiStatus.message}
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className="row space-between">
        {!!location && !!location._id ? (
          <Button
            variant="outlined"
            onClick={() => updateLocation(location._id)}
          >
            Save Changes
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => createLocation()}>
            Create Location
          </Button>
        )}
      </div>
    </div>
  );
}
