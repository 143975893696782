import axios from 'axios';
import baseURL from './apiURL';

const locationSvc = {
  getLocation,
  getLocations,
  createLocation,
  updateLocation,
  deleteLocation,
  uploadImage,
};

function getLocation(id) {
  return new Promise((resolve, reject) => {
    if (!!id) {
      axios({ method: 'get', url: `${baseURL}/location/${id}` })
        .then((res) => {
          if (!!res && !!res.status && !!res.data && res.status === 200) {
            resolve({
              success: true,
              message: `Fetched location`,
              data: res.data,
            });
          } else {
            reject({ success: false, message: 'Unable to find location' });
          }
        })
        .catch((err) => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing id' });
    }
  });
}

function getLocations() {
  return new Promise((resolve, reject) => {
    axios({ method: 'get', url: `${baseURL}/location/` })
      .then((res) => {
        if (!!res && !!res.status && !!res.data && res.status === 200) {
          resolve({
            success: true,
            message: `Fetched locations`,
            data: res.data,
          });
        } else {
          reject({ success: false, message: 'Unable to find locations' });
        }
      })
      .catch((err) => {
        reject({
          success: false,
          message: 'An error occurred, please try again',
        });
      });
  });
}

function createLocation(token, location) {
  function isValid(o) {
    return (
      !!o && !!o.name && !!o.description && !!o.images && !!o.coords && !!o.w3w
    );
  }
  return new Promise((resolve, reject) => {
    if (!!token && !!location && isValid(location)) {
      axios({
        method: 'post',
        url: `${baseURL}/location/`,
        data: location,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Created location`,
            });
          } else {
            reject({ success: false, message: 'Unable to create location' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}
function updateLocation(token, locationId, location) {
  function isValid(o) {
    return !!o && !!o.name && !!o.description && !!o.coords && !!o.w3w;
  }
  return new Promise((resolve, reject) => {
    if (!!token && !!locationId && !!location && isValid(location)) {
      axios({
        method: 'put',
        url: `${baseURL}/location/${locationId}`,
        data: location,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Updated location`,
            });
          } else {
            reject({ success: false, message: 'Unable to update location' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

function deleteLocation(token, locationId) {
  return new Promise((resolve, reject) => {
    if (!!token && !!locationId) {
      axios({
        method: 'delete',
        url: `${baseURL}/location/${locationId}`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Deleted location`,
            });
          } else {
            reject({ success: false, message: 'Unable to delete location' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

function uploadImage(token, id, name, imgData) {
  return new Promise((resolve, reject) => {
    if (!!token && !!id && !!name && !!imgData) {
      const formattedName = name.replace(/\s/g, '');

      axios({
        method: 'post',
        url: `${baseURL}/location/upload/${id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: { imgData, formattedName },
      })
        .then((res) => {
          if (!!res && !!res.data && !!res.data.imgURL && res.status === 200) {
            resolve({ success: true, imgURL: res.data.imgURL });
          } else {
            reject({ success: false, message: 'Unable to upload image' });
          }
        })
        .catch((err) => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

export default locationSvc;
