import axios from 'axios';
import baseURL from './apiURL';

const authSvc = {
  attemptLogin,
};

function attemptLogin(email, password) {
  return new Promise((resolve, reject) => {
    if (!!email && !!password) {
      axios({
        method: 'post',
        url: `${baseURL}/auth`,
        data: { email, password },
      })
        .then((res) => {
          if (
            !!res &&
            !!res.status &&
            !!res.data &&
            !!res.data.token &&
            res.status === 200
          ) {
            resolve({
              success: true,
              message: `Successfully logged in`,
              token: res.data.token,
            });
          } else {
            reject({ success: false, message: 'Incorrect details' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

export default authSvc;
