import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AnnouncementForm from '../components/AnnouncementForm';
import { useToken } from '../context/authContext';
import announcementSvc from '../services/announcementService';

// Needs to have a form to create new announcements and to create notifications with the announcement

// Needs to display all of the announcements that are live in correct order

// Table displaying Announcement clicks

// List of all announcements

function dayStart() {
  const now = new Date();
  const d = now.getDate();
  const m = now.getMonth();
  const yyyy = now.getFullYear();
  return new Date(yyyy, m, d);
}
function dayEnd() {
  const now = new Date();
  const d = now.getDate();
  const m = now.getMonth();
  const yyyy = now.getFullYear();
  return new Date(yyyy, m, d, 23, 59, 59);
}
function isActive(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const now = new Date();
  return endDate >= now;
}

const Announcements = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [pinned, setPinned] = useState();
  const [active, setActive] = useState();
  const [inactive, setInactive] = useState();
  const token = useToken();
  useEffect(() => {
    announcementSvc
      .getAnnouncements(token)
      .then((res) => {
        const a = res.data;
        let pinned = [];
        let active = [];
        let inactive = [];
        a.forEach((i) => {
          if (i.isPinned) {
            pinned.push(i);
          } else if (isActive(i.startDate, i.endDate)) {
            active.push(i);
          } else if (!isActive(i.startDate, i.endDate)) {
            inactive.push(i);
          }
        });
        setPinned(pinned);
        setActive(active);
        setInactive(inactive);
      })
      .catch((err) => {
        console.log(err);
        alert('error fetching announcements');
      });
  }, []);

  return (
    <div>
      <div className="row center">
        <h1>Announcements</h1>
      </div>
      <div className="row center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsCreating(!isCreating)}
        >
          {isCreating ? 'Cancel' : 'Create New'}
        </Button>
      </div>
      {isCreating && (
        <div className="row center">
          <AnnouncementForm isCreating={true} />
        </div>
      )}
      <div className="row center">
        <h2>Pinned</h2>
      </div>
      {!!pinned && pinned.length >= 1 ? (
        pinned.map((p, i) => (
          <div className="row center">
            <AnnouncementForm announcement={p} key={`pinned-${i}`} />
          </div>
        ))
      ) : (
        <div className="row center">
          <p>No Pinned announcements</p>
        </div>
      )}
      <div className="row center">
        <h2>Active</h2>
      </div>
      {!!active && active.length >= 1 ? (
        active.map((p, i) => (
          <div className="row center">
            <AnnouncementForm announcement={p} key={`active-${i}`} />
          </div>
        ))
      ) : (
        <div className="row center">
          <p>No Active announcements</p>
        </div>
      )}

      <div className="row center">
        <h2>Inactive</h2>
      </div>
      {!!inactive &&
        inactive.map((p, i) => (
          <div className="row center">
            <AnnouncementForm announcement={p} key={`inactive-${i}`} />
          </div>
        ))}
    </div>
  );
};

export default Announcements;
