import React, { useState } from 'react';
import Notifications from './Notifications';
import Shoots from './Shoots';
import Locations from './Locations';
import Announcements from './Announcements';

import { AppBar, Tab, Tabs, Box, Typography } from '@material-ui/core';

export default function AdminScreen() {
  const [activeTab, setActiveTab] = useState(0);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div>
      <div>
        <AppBar position="static">
          <Tabs value={activeTab} onChange={(e, n) => setActiveTab(n)}>
            <Tab label="Locations" {...a11yProps(0)} />
            <Tab label="Shoots" {...a11yProps(1)} />
            <Tab label="Notifications" {...a11yProps(2)} />
            <Tab label="Announcements" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
      </div>
      <div style={{ marginTop: '80px' }}>
        <TabPanel value={activeTab} index={0}>
          <Locations />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Shoots />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Notifications />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <Announcements />
        </TabPanel>
      </div>
    </div>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
