import { Button } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import LocationForm from '../components/LocationForm';
import { useLocations } from '../context/locationsContext';

export default function Locations() {
  const { locations } = useLocations();
  const [isCreating, setIsCreating] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [text, setText] = useState('');
  function handleSearch(text) {
    setText(text);
  }

  useEffect(() => {
    if (text !== '') {
      const filtered = locations.filter((l) =>
        l.name.toLowerCase().includes(text.toLowerCase())
      );
      setFilteredLocations(filtered);
    } else {
      setFilteredLocations(locations);
    }
  }, [text]);
  useEffect(() => {
    setFilteredLocations(locations);
  }, [locations]);

  return (
    <div>
      <div className="row center">
        <h1>Locations</h1>
      </div>
      <div className="row center">
        <TextField
          id="outlined-basic"
          label="Search By Name"
          variant="outlined"
          value={text}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="row center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsCreating(!isCreating)}
        >
          {isCreating ? 'Cancel' : 'Create New'}
        </Button>
      </div>

      {!isCreating ? (
        <div className="col center" style={{ width: '100%' }}>
          {!!filteredLocations &&
          !!filteredLocations.length &&
          filteredLocations.length > 0 ? (
            filteredLocations.map((l) => (
              <LocationForm location={l} key={l._id} />
            ))
          ) : (
            <p>No locations to display</p>
          )}
        </div>
      ) : (
        <div className="row center">
          <LocationForm />
        </div>
      )}
    </div>
  );
}
