import axios from 'axios';
import baseURL from './apiURL';

const notificationSvc = {
  createNotification,
  getNotifications,
};

function getNotifications(token) {
  return new Promise((resolve, reject) => {
    if (!!token) {
      axios({
        method: 'get',
        url: `${baseURL}/notification/`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && !!res.data && res.status === 200) {
            resolve({
              success: true,
              message: `Fetched notifications`,
              data: res.data,
            });
          } else {
            reject({ success: false, message: 'Unable to find notifications' });
          }
        })
        .catch((err) => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Unauthorized' });
    }
  });
}

function createNotification(token, n) {
  function isValid(o) {
    return !!o && !!o.title && !!o.message;
  }
  return new Promise((resolve, reject) => {
    if (!!token && !!n && isValid(n)) {
      axios({
        method: 'post',
        url: `${baseURL}/notification/`,
        data: n,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200 && !!res.data) {
            resolve({
              success: true,
              message: `Created notification`,
            });
          } else {
            reject({ success: false, message: 'Unable to create notification' });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}
export default notificationSvc;
