import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import LoginForm from './components/LoginForm';
import { AuthContextProvider, useAuth } from './context/authContext';
import { LocationContextProvider } from './context/locationsContext';
import { ShootContextProvider } from './context/shootContext';
import AdminScreen from './screens/AdminScreen';
function App() {
  return (
    <ContextProviders>
      <Router>
        <Switch>
          <Route path="/login">
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                backgroundColor: '000000',
              }}
            >
              <LoginForm />
            </div>
          </Route>
          <PrivateRoute path="/admin">
            <AdminScreen />
          </PrivateRoute>
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    </ContextProviders>
  );
}

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default App;

const ContextProviders = ({ children }) => (
  <AuthContextProvider>
    <LocationContextProvider>
      <ShootContextProvider>{children}</ShootContextProvider>
    </LocationContextProvider>
  </AuthContextProvider>
);
