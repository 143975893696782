import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ShootForm from "../components/ShootForm";
import { useLocations } from "../context/locationsContext";
import { useShoots } from "../context/shootContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

export default function Shoots() {
  const { locations } = useLocations();
  const { shoots } = useShoots();
  const [isCreating, setIsCreating] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [filteredShoots, setFilteredShoots] = useState();
  const [sortedLocations, setSortedLocations] = useState(sortLocations());
  useEffect(() => {
    if (locationId !== "" && !!shoots) {
      const filtered = shoots.filter((s) => s.locationId === locationId);
      setFilteredShoots(filtered);
    }
  }, [locationId]);

  function sortLocations() {
    const sorted = locations.sort((a, b) => a.name.localeCompare(b.name));
    return sorted;
  }

  return (
    <div>
      <div className="row center">
        <h1>Shoots</h1>
      </div>
      <div className="row center">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setIsCreating(!isCreating)}
        >
          {isCreating ? "Cancel" : "Create New"}
        </Button>
      </div>
      {!isCreating && (
        <div className="row center">
          <Autocomplete
            id="country-select-demo"
            style={{ width: 300 }}
            options={sortedLocations}
            getOptionLabel={(option) => {
              return option.name;
            }}
            onChange={(e, newValue) => {
              setLocationId(newValue._id);
            }}
            autoHighlight
            renderOption={(option) => (
              <React.Fragment>{option.name}</React.Fragment>
            )}
            disableClearable={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a Location"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </div>
      )}
      {!isCreating ? (
        <div className="col center" style={{ width: "100%" }}>
          {!!filteredShoots &&
          !!filteredShoots.length &&
          filteredShoots.length > 0 ? (
            filteredShoots.map((s, i) => <ShootForm shoot={s} key={i} />)
          ) : typeof filteredShoots === "undefined" ? (
            <p>Select a location to view shoots</p>
          ) : (
            <p>No shoots to display</p>
          )}
        </div>
      ) : (
        <div className="row center">
          <ShootForm isCreating={true} />
        </div>
      )}
    </div>
  );
}
