import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import NotificationForm from '../components/NotificationForm';
import { useToken } from '../context/authContext';
import notificationSvc from '../services/notificationService';

export default function Notifications() {
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState();
  const token = useToken();
  useEffect(() => {
    notificationSvc
      .getNotifications(token)
      .then((res) => {
        if (!!res && !!res.success && res.success && !!res.data) {
          setNotifications(res.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch();
  }, []);
  return (
    <div>
      <div className="row center">
        <h1>Notifications</h1>
      </div>
      <div className="row center">
        <Button onClick={() => setIsCreating(!isCreating)} variant="outlined">
          {isCreating ? 'Cancel' : 'Create'}
        </Button>
      </div>
      {isCreating ? (
        <div className="row center">
          <NotificationForm />
        </div>
      ) : (
        <div className="col center">
          {isLoading ? (
            <h2>Loading</h2>
          ) : !!notifications &&
            !!notifications.length &&
            notifications.length > 0 ? (
            <></>
          ) : (
            <div className="row center">
              <p>No notifications to display</p>
            </div>
          )}
          {!isLoading &&
            !!notifications &&
            !!notifications.length &&
            notifications.map((n, i) => (
              <div
                className="form-container"
                key={i}
                style={{ padding: '10px' }}
              >
                <p>Title:&nbsp;&nbsp;&nbsp;{n.title}</p>
                <p>Message:&nbsp;&nbsp;&nbsp;{n.message}</p>
                {!!n && !!n.locationId && (
                  <p>LocationId:&nbsp;&nbsp;&nbsp;{n.locationId}</p>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
