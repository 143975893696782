import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useToken } from '../context/authContext';
import announcementSvc from '../services/announcementService';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

export default function AnnouncementForm({ isCreating, announcement }) {
  const token = useToken();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState();
  const [buttonText, setButtonText] = useState();
  const [link, setLink] = useState();
  const [isPinned, setIsPinned] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clicks, setClicks] = useState();
  const [sendNotification, setSendNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState();
  const [notificationMessage, setNotificationMessage] = useState();
  const [apiStatus, setApiStatus] = useState(); // { success, message }
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    if (!!announcement) {
      const {
        title,
        description,
        buttonText,
        link,
        type,
        isPinned,
        startDate,
        endDate,
        clicks,
      } = announcement;
      if (!!title) {
        setTitle(title);
      }
      if (!!description) {
        setDescription(description);
      }
      if (!!buttonText) {
        setButtonText(buttonText);
      }
      if (!!link) {
        setLink(link);
      }

      if (typeof isPinned === 'boolean') {
        setIsPinned(isPinned);
      }
      if (!!startDate) {
        setStartDate(startDate);
      }
      if (!!endDate) {
        setEndDate(endDate);
      }
      if (!!clicks) {
        setClicks(clicks);
      }
    }
    setEditable(typeof isCreating === 'boolean' ? isCreating : false);
  }, []);
  function isValid(type) {
    switch (type) {
      case 'post':
        return (
          !!title &&
          !!description &&
          !!link &&
          !!buttonText &&
          !!startDate &&
          !!endDate &&
          typeof isPinned === 'boolean'
        );
      case 'put':
        return (
          !!title &&
          !!description &&
          !!link &&
          !!buttonText &&
          !!startDate &&
          !!endDate &&
          typeof isPinned === 'boolean'
        );
      case 'notification':
        return !!notificationTitle && !!notificationMessage;
    }
  }
  function createAnnouncement() {
    setApiStatus();
    if (isValid('post')) {
      if (sendNotification && isValid('notification')) {
        setIsLoading(true);
        announcementSvc
          .createAnnouncement(token, {
            title,
            description,
            link,
            buttonText,
            startDate,
            endDate,
            isPinned,
            sendNotification,
            notificationMessage,
            notificationTitle,
          })
          .then((res) => {
            setApiStatus(res);
            setIsLoading(false);
          })
          .catch((err) => {
            setApiStatus(err);
            console.log(err);
            setIsLoading(false);
          });
      } else if (sendNotification && !isValid('notification')) {
        alert('Missing notification details');
      } else {
        setIsLoading(true);
        announcementSvc
          .createAnnouncement(token, {
            title,
            description,
            link,
            buttonText,
            startDate,
            endDate,
            isPinned,
          })
          .then((res) => {
            setApiStatus(res);
            setIsLoading(false);
          })
          .catch((err) => {
            setApiStatus(err);
            console.log(err);
            setIsLoading(false);
          });
      }
    } else {
      alert('Missing details in form');
    }
  }
  function updateAnnouncement() {
    if (isValid('put')) {
      setApiStatus();
      setIsLoading(true);
      announcementSvc
        .updateAnnouncement(token, {
          title,
          description,
          link,
          buttonText,
          startDate,
          endDate,
          isPinned,
        })
        .then((res) => {
          setApiStatus(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setApiStatus(err);
          console.log(err);
          setIsLoading(false);
        });
    } else {
      alert('Missing details for update');
    }
  }
  function deleteAnnouncement() {
    setApiStatus();
    setIsLoading(true);
    announcementSvc
      .deleteAnnouncement(token, announcement._id)
      .then((res) => {
        setIsLoading(false);
        setApiStatus(res);
      })
      .catch((err) => {
        setIsLoading(false);
        setApiStatus(err);
      });
  }

  return (
    <div className="form-container" style={{ maxWidth: '500px' }}>
      {!!announcement && !!announcement._id && (
        <div className="row flex-end">
          <Button variant="outlined" onClick={deleteAnnouncement}>
            Delete Announcement
          </Button>
        </div>
      )}
      {!!announcement && !!announcement._id && (
        <div className="row flex-end">
          <Button variant="outlined" onClick={() => setEditable(!editable)}>
            {editable ? 'Cancel Editing' : 'Edit Announcement'}
          </Button>
        </div>
      )}
      <div className="row">
        <TextField
          id="title"
          label="Title"
          variant="outlined"
          value={!!title ? title : ''}
          onChange={(e) => setTitle(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div className="row">
        <TextField
          id="description"
          label="Description"
          variant="outlined"
          value={!!description ? description : ''}
          onChange={(e) => setDescription(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div className="row">
        <TextField
          id="buttonText"
          label="Button Text"
          variant="outlined"
          value={!!buttonText ? buttonText : ''}
          onChange={(e) => setButtonText(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div className="row">
        <TextField
          id="link"
          label="link"
          variant="outlined"
          value={!!link ? link : ''}
          onChange={(e) => setLink(e.target.value)}
          disabled={!editable}
        />
      </div>
      <div className="row">
        {/* isPinned */}
        <FormControlLabel
          control={
            <Checkbox
              name="isPinned"
              checked={typeof isPinned === 'boolean' ? isPinned : false}
              onChange={(e) => setIsPinned(e.target.checked)}
            />
          }
          label="Pinned News Item"
          disabled={!editable}
        />
      </div>
      {/* All of the date stuffs */}
      <div className="row center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            label="Start Date"
            variant="outlined"
            inputVariant="outlined"
            value={!!startDate ? new Date(startDate) : new Date()}
            onChange={setStartDate}
            style={{ minWidth: '300px' }}
            disabled={!editable}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="row center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            label="End Date"
            variant="outlined"
            inputVariant="outlined"
            value={!!endDate ? new Date(endDate) : new Date()}
            onChange={setEndDate}
            style={{ minWidth: '300px' }}
            disabled={!editable}
          />
        </MuiPickersUtilsProvider>
      </div>
      {isCreating && (
        <div className="row">
          {/* isPinned */}
          <FormControlLabel
            control={
              <Checkbox
                name="sendNotification"
                checked={
                  typeof sendNotification === 'boolean'
                    ? sendNotification
                    : false
                }
                onChange={(e) => setSendNotification(e.target.checked)}
              />
            }
            label="Send Notification"
          />
        </div>
      )}
      {!isCreating && (
        <div className="row space-between">
          <p>Clicks:</p>
          <p>{clicks}</p>
        </div>
      )}
      {/* If creating, notification deets */}
      {!!isCreating && isCreating && sendNotification && (
        <>
          <div className="row">
            <TextField
              id="notification-title"
              label="Notification Title"
              variant="outlined"
              value={!!notificationTitle ? notificationTitle : ''}
              onChange={(e) => setNotificationTitle(e.target.value)}
            />
          </div>
          <div className="row">
            <TextField
              id="notification-message"
              label="Notification Message"
              variant="outlined"
              value={!!notificationMessage ? notificationMessage : ''}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
          </div>
          <div className="row">
            <p>Notification will link to the news section</p>
          </div>
        </>
      )}
      <div className="row center">
        {!!apiStatus ? (
          <p style={apiStatus.success === false ? { color: 'red' } : {}}>
            {apiStatus.message}
          </p>
        ) : (
          <></>
        )}
      </div>
      <div className="row center">{isLoading && <p>Loading</p>}</div>
      <div className="row center">
        {!!announcement && !!announcement._id ? (
          <Button
            variant="outlined"
            onClick={() => updateAnnouncement(announcement._id)}
          >
            Save Changes
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => createAnnouncement()}>
            Create Announcement{' '}
            {sendNotification ? ' and Send Notification' : ''}
          </Button>
        )}
      </div>
    </div>
  );
}
