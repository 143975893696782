import axios from 'axios';
import baseURL from './apiURL';

const announcementSvc = {
  createAnnouncement,
  getAnnouncements,
  updateAnnouncement,
  deleteAnnouncement,
};

function isValid(method, obj) {
  switch (method) {
    case 'post':
      return (
        !!obj &&
        !!obj.title &&
        !!obj.description &&
        !!obj.link &&
        typeof obj.isPinned === 'boolean'
      );
    case 'put':
      return !!obj && !!obj._id;
    case 'delete':
      return !!obj && !!obj._id;
    case 'notification':
      return !!obj && !!obj.notificationTitle && !!obj.notificationMessage;
  }
}

function getAnnouncements(token) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${baseURL}/announcement`,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (!!res && !!res.status && !!res.data && res.status === 200) {
          resolve({
            success: true,
            message: `Fetched announcements`,
            data: res.data,
          });
        } else {
          reject({ success: false, message: 'Unable to find announcements' });
        }
      })
      .catch((err) => {
        reject({
          success: false,
          message: 'An error occurred, please try again',
        });
      });
  });
}

function createAnnouncement(token, announcement) {
  return new Promise((resolve, reject) => {
    if (!!token && isValid('post', announcement)) {
      axios({
        method: 'post',
        url: `${baseURL}/announcement/`,
        data: announcement,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Created announcement`,
            });
          } else {
            reject({
              success: false,
              message: 'Unable to create announcement',
            });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}
function updateAnnouncement(token, announcementId, announcement) {
  return new Promise((resolve, reject) => {
    if (
      !!token &&
      !!announcementId &&
      !!announcement &&
      isValid('put', announcement)
    ) {
      axios({
        method: 'put',
        url: `${baseURL}/announcement/${announcementId}`,
        data: announcement,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Updated announcement`,
            });
          } else {
            reject({
              success: false,
              message: 'Unable to update announcement',
            });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

function deleteAnnouncement(token, announcementId) {
  return new Promise((resolve, reject) => {
    if (!!token && !!announcementId) {
      axios({
        method: 'delete',
        url: `${baseURL}/announcement/${announcementId}`,
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!!res && !!res.status && res.status === 200) {
            resolve({
              success: true,
              message: `Deleted announcement`,
            });
          } else {
            reject({
              success: false,
              message: 'Unable to delete announcement',
            });
          }
        })
        .catch(() => {
          reject({
            success: false,
            message: 'An error occurred, please try again',
          });
        });
    } else {
      reject({ success: false, message: 'Missing details' });
    }
  });
}

export default announcementSvc;
