import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { useLocations } from "../context/locationsContext";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useToken } from "../context/authContext";
import shootSvc from "../services/shootService";
import {
  Button,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import notificationSvc from "../services/notificationService";
import { Autocomplete } from "@material-ui/lab";

/**  
  locationId: String,
  date: Date,
  status: String, // active, cancelled, processing, ready
  photographer: String,
  albumURL: String
*/

export default function ShootForm({ shoot, isCreating }) {
  const { locations, getLocations } = useLocations();
  const token = useToken();
  const [locationId, setLocationId] = useState(
    !!shoot && !!shoot.locationId ? shoot.locationId : ""
  );
  const [date, setDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [status, setStatus] = useState("active");
  const [photographer, setPhotographer] = useState();
  const [albumURL, setAlbumURL] = useState();
  const statusArray = ["active", "ready", "processing", "cancelled"];
  const [apiStatus, setApiStatus] = useState(); // { success, message }
  const [title, setTitle] = useState();
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!!shoot) {
      if (!!shoot.locationId) setLocationId(shoot.locationId);
      if (!!shoot.date) setDate(new Date(shoot.date));
      if (!!shoot.status) setStatus(shoot.status.toLowerCase());
      if (!!shoot.photographer) setPhotographer(shoot.photographer);
      if (!!shoot.albumURL) setAlbumURL(shoot.albumURL);
      if (!!shoot.endDate) setEndDate(new Date(shoot.endDate));
    }
  }, [shoot]);
  useEffect(() => {
    if (!!locations && !!!locations.length && locations.length < 1) {
      getLocations();
    }
  }, []);

  function updateShoot(shootId) {
    setApiStatus();
    shootSvc
      .updateShoot(token, shootId, {
        locationId,
        date,
        endDate,
        status,
        photographer,
        albumURL,
      })
      .then((res) => {
        if (!!res && !!res.success && res.success) {
          setApiStatus({ success: true, message: "Updated shoot" });
        } else {
          setApiStatus({
            success: false,
            message: "Unable to update shoot",
          });
        }
      })
      .catch(() => {
        setApiStatus({ success: false, message: "Unable to update shoot" });
      });
  }

  function createShoot() {
    setIsLoading(true);
    setApiStatus();
    if (!!locationId && !!date && !!endDate && !!status) {
      shootSvc
        .createShoot(token, {
          locationId,
          date,
          endDate,
          status,
          photographer,
          albumURL,
        })
        .then((res) => {
          if (!!res && !!res.success && res.success) {
            if (!!title && !!message) {
              notificationSvc
                .createNotification(token, {
                  locationId,
                  title,
                  message,
                })
                .then((res) => {
                  if (!!res && !!res.success) {
                    setApiStatus({
                      status: "success",
                      message: `Successfully sending notifications and created shoot`,
                    });
                    setIsLoading(false);
                  } else {
                    setApiStatus({
                      status: "failed",
                      message:
                        "Unable to create notification but created shoot",
                    });
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setApiStatus({
                    status: "failed",
                    message: "Unable to create notification but created shoot",
                  });
                  setIsLoading(false);
                });
            } else {
              alert(
                "Incomplete details for sending notification, shoot created successfully"
              );
              setApiStatus({
                status: "failed",
                message: "Unable to create notification but created shoot",
              });
              setIsLoading(false);
            }
          } else {
            setApiStatus({
              success: false,
              message:
                "Unable to create shoot, make sure location, status, start and end date and photographer are present",
            });
            setIsLoading(false);
          }
        })
        .catch(() => {
          setApiStatus({
            success: false,
            message: "Unable to create shoot",
          });
          setIsLoading(false);
        });
    } else {
      alert("Incomplete details");
      setIsLoading(false);
    }
  }
  function handleDelete() {
    setApiStatus();
    shootSvc
      .deleteShoot(token, shoot._id)
      .then((res) => {
        if (!!res && !!res.success && res.success) {
          setApiStatus({ success: true, message: "Deleted shoot" });
        } else {
          setApiStatus({ success: false, message: "Unable to delete shoot" });
        }
      })
      .catch(() => {
        setApiStatus({ success: false, message: "Unable to delete shoot" });
      });
  }

  return (
    <div className="form-container">
      {!!shoot && !!shoot._id && (
        <div className="row flex-end">
          <Button variant="outlined" onClick={handleDelete}>
            Delete Shoot
          </Button>
        </div>
      )}
      <div className="row center">
        <Autocomplete
          id="country-select-demo"
          style={{ width: 300 }}
          options={locations}
          getOptionLabel={(option) => {
            return option.name;
          }}
          onChange={(e, newValue) => {
            setLocationId(newValue._id);
          }}
          autoHighlight
          renderOption={(option) => (
            <React.Fragment>{option.name}</React.Fragment>
          )}
          disableClearable={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a Location"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </div>

      {/* Status Selector */}
      <div className="row center">
        <Select
          labelId="demo-simple-select"
          id="demo-simple-selec"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          style={{ minWidth: "300px" }}
        >
          {statusArray.map((s, i) => (
            <MenuItem key={i} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </div>

      {/* Start Date Selector */}
      <div className="row center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            label="Start Date"
            variant="outlined"
            inputVariant="outlined"
            value={new Date(date)}
            onChange={setDate}
            style={{ minWidth: "300px" }}
            format="hh:mm a dd/MM/yyyy"

          />
        </MuiPickersUtilsProvider>
      </div>
      {/* End Date Selector */}
      <div className="row center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            label="End"
            variant="outlined"
            inputVariant="outlined"
            value={new Date(endDate)}
            onChange={setEndDate}
            style={{ minWidth: "300px" }}
            format="hh:mm a dd/MM/yyyy"
          />
        </MuiPickersUtilsProvider>
      </div>
      {!!endDate && !!date && new Date(date) >= new Date(endDate) && (
        <div className="row center">
          <p style={{ color: "red" }}>End date must be after the Start date</p>
        </div>
      )}

      {/* Photographer input */}
      <div className="row center">
        <TextField
          id="photographer"
          label="Photographer"
          variant="outlined"
          value={!!photographer ? photographer : ""}
          onChange={(e) => setPhotographer(e.target.value)}
          style={{ minWidth: "300px" }}
        />
      </div>

      {/* AlbumURL input */}
      <div className="row center">
        <TextField
          id="albumurl"
          label="Album URL"
          variant="outlined"
          value={!!albumURL ? albumURL : ""}
          onChange={(e) => setAlbumURL(e.target.value)}
          style={{ minWidth: "300px" }}
        />
      </div>
      {isCreating === true && (
        <div>
          <div className="row center">
            <h3>Notification Details</h3>
          </div>
          <div className="row center">
            <a
              href="https://unicode.org/emoji/charts/full-emoji-list.html"
              target="_blank"
            >
              Emoji's
            </a>
          </div>
          {/* Title input */}
          <div className="row center">
            <TextField
              id="title"
              label="Title"
              variant="outlined"
              value={!!title ? title : ""}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {/* Message input */}
          <div className="row center">
            <TextField
              id="message"
              label="Message"
              variant="outlined"
              value={!!message ? message : ""}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="row center">
            {!!apiStatus ? (
              <p style={apiStatus.success === false ? { color: "red" } : {}}>
                {apiStatus.message}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className="row center">{isLoading && <p>Loading</p>}</div>
          <div className="row center">
            {!!shoot && !!shoot._id ? (
              <></>
            ) : (
              <p>
                Creating a shoot will send a notification if shoot creation
                succeeds
              </p>
            )}
          </div>
        </div>
      )}
      {!isCreating && (
        <div className="row center">
          {!!apiStatus ? (
            <p style={apiStatus.success === false ? { color: "red" } : {}}>
              {apiStatus.message}
            </p>
          ) : (
            <></>
          )}
        </div>
      )}
      <div className="row center">
        {!!shoot && !!shoot._id ? (
          <Button variant="outlined" onClick={() => updateShoot(shoot._id)}>
            Save Changes
          </Button>
        ) : (
          <Button variant="outlined" onClick={() => createShoot()}>
            Create Shoot
          </Button>
        )}
      </div>
    </div>
  );
}
