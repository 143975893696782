import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useAuth } from '../context/authContext';
import { Redirect } from 'react-router';
export default function LoginForm() {
  const { token, login } = useAuth();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [shouldRedirect, setShouldRedirect] = useState(!!token ? true : false);
  function attemptLogin() {
    login(email, password)
      .then((r) => {
        setShouldRedirect(true);
      })
      .catch((err) => {
        setErrorMessage('Login Failed');
      });
  }

  return shouldRedirect ? (
    <Redirect to="/admin" />
  ) : (
    <div
      style={{
        padding: '15px',
        maxWidth: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className="row">
        <TextField
          id="email"
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="row">
        <TextField
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="row">
        <Button variant="outlined" color="primary" onClick={attemptLogin}>
          Login
        </Button>
      </div>
      <div className="row">
        {!!errorMessage ? (
          <p style={{ color: 'red', width: '100%', textAlign: 'center' }}>
            {errorMessage}
          </p>
        ) : null}
      </div>
    </div>
  );
}
