import React, { createContext, useContext, useEffect, useState } from "react";
import locationsSvc from "../services/locationService";
export const LocationContext = createContext();

export function LocationContextProvider({ children }) {
  const locations = useProvideLocations();
  return (
    <LocationContext.Provider value={locations}>
      {children}
    </LocationContext.Provider>
  );
}

function useProvideLocations() {
  const [locations, setLocations] = useState();

  useEffect(() => {
    getLocations();
  }, []);
  function getLocations() {
    locationsSvc
      .getLocations()
      .then((res) => {
        if (!!res.data) {
          setLocations(res.data);
        } else {
          alert("Error getting locations");
        }
      })
      .catch((err) => alert("Error getting locations"));
  }
  return {
    locations,
    setLocations,
    getLocations,
  };
}

export function useLocations() {
  return useContext(LocationContext);
}
