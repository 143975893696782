import React, { createContext, useContext, useEffect, useState } from 'react';
import authSvc from '../services/authService';
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useProvideAuth() {
  const [token, setToken] = useState();
  const [user, setUser] = useState();
  useEffect(() => {
    if (!!sessionStorage.getItem('auth_token')) {
      console.log('token found');
      const jwt = jwtDecode(sessionStorage.getItem('auth_token'));
      console.log(jwt.exp);
      console.log(
        new Date(jwt.exp * 1000),
        new Date(jwt.exp * 1000) > new Date().getTime() + 86400
      );
      if (
        !!jwt.exp &&
        new Date(jwt.exp * 1000).getTime() > new Date().getTime() + 86400
      ) {
        setUser(jwt);
        setToken(sessionStorage.getItem('auth_token'));
      } else {
        console.log('outdated token');
      }
    }
  }, []);

  const login = (email, password) => {
    return new Promise((resolve, reject) => {
      authSvc
        .attemptLogin(email, password)
        .then((res) => {
          if (!!res.token) {
            setToken(res.token);
            sessionStorage.setItem('auth_token', res.token);
            setUser(jwtDecode(res.token));
            resolve(res.token);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          alert('failed login');
          reject();
        });
    });
  };

  const logout = () => {
    setToken();
    sessionStorage.clear();
  };

  return {
    token,
    user,
    login,
    logout,
  };
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useToken() {
  const { token } = useContext(AuthContext);
  return token;
}
